// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
Rails.start();
require("@rails/activestorage").start()
require("channels")
require("@nathanvda/cocoon")
require ("jquery-timepicker/jquery.timepicker")

//= require jquery3
//= require popper
//= require bootstrap
import "../stylesheets/application.scss";

$("form").removeAttr("data-remote")
$("input[type=submit]").removeAttr("data-disable-with")

// Admin
require("./admin/admin");

import "./daterangepicker.min.js"

// Pages
import "./custom.js"
import "./contacts.js"
import "./partners.js"
import "./agent_applications.js"
import "./drivers.js"
import "./line_connect.js"
import "./project.js"
import "./datetimepicker.js"
import "./work_schedule.js"
import "./expenses.js"
import "./timesheet"
import "./reports.js"
import "./vehicles.js"
import "./service_history.js"
import "./history_report.js"
