let timeDisplay = document.getElementById("time-hours");
let timeHidden = document.getElementById('time_hours_param');

function refreshTime() {
  let date = new Date();
  let hour = date.getHours().toString().padStart(2, '0');
  let minute = date.getMinutes().toString().padStart(2, '0');
  let second = date.getSeconds().toString().padStart(2, '0');

  let timeString = `${hour} : ${minute} : ${second}`;
  timeDisplay.innerHTML = timeString;
  timeHidden.setAttribute('value', timeString);
}
if ($("#time-checkout").length) {
  setInterval(refreshTime, 1000);
}

if ($("#get-data-supplier-region").length) {
  getDataSupplierRegion($("#timesheet-project-select"));
}

$("#timesheet-project-select").change(function(){
  getDataSupplierRegion($(this));
});

function getDataSupplierRegion(element) {
  let selectedValue = $(element).val();
  let host = window.location.protocol + "//" + window.location.host;
  $.ajax({
    url: host + '/admin/region_select_timesheet',
    type: 'GET',
    dataType: 'json',
    data: { project_id: selectedValue },
    success: function(data) {
      var regionHtml = '<span class="font-inter">着車場所：</span>';
      if (parseInt(data) == 0) {
        $("#region-project-index").html(regionHtml);
      } else {
        $("#region-project-index").html(regionHtml + data);
      }
    }
  });
}

$(document).ready(function() {
  $('.image-link').click(function() {
    $('.carousel-item').removeClass('active');
    var index = $(this).closest('.pt-2').index();
    $('.carousel-item').eq(index).addClass('active');
  });
});
