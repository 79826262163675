$(document).ready(function () {
  const contactTypeSelect = $("#contact_type_select");
  const messageTextarea = $("#message-content-sms");

  $("#status-dropdown").on("change", function () {
    const selectedValue = $(this).val();
    const statuses = [
      "interview_scheduled",
      "interview_offline",
      "under_interview",
      "no_contract",
      "failed",
      "interview_cancelled",
      "contracted",
      "ghosting",
    ];
    if (statuses.includes(selectedValue)) {
      $("#interview-date-wraper").removeClass("hidden");
      $("#interview-url-wraper").removeClass("hidden");
    } else {
      $("#interview-date-wraper").addClass("hidden");
      $("#interview-url-wraper").addClass("hidden");
    }
  });

  $(".contact-interview-datetime").on("change", function () {
    const selectedValue = $("#status-dropdown").val();
    const statuses = ["interview_scheduled", "under_interview", "interview_offline"];
    if (statuses.includes(selectedValue)) {
      $("#contact-info-update").attr("data-target", "#submit-modal");
    } else {
      $("#contact-info-update").attr("data-target", "#confirm-submit-modal");
    }
  });

  function generateOptions(options) {
    return Object.keys(options).map(function (key) {
      return `<option value="${options[key]}">${key}</option>`;
    });
  }

  function handleSetSMS(event) {
    event.preventDefault();
    const contactId = $(this).data("id");
    const content = $(this).data("content");
    const number = $(this).data("number");
    const templates = $(this).data("templates");
    const options = generateOptions(templates);
    contactTypeSelect.empty().append(options);
    $("#message-content").val(content);
    $("#send-sms-modal-number").html(number);
    $("#send-sms-form input[name=contact_id]").val(contactId);
    populateMessageTextarea();
  }

  $(".contacts-list .send-sms").on("click", handleSetSMS);
  $(".card-list-mobile .send-sms").on("click", handleSetSMS);

  function populateMessageTextarea() {
    const selectedValue = contactTypeSelect.val();
    messageTextarea.val(selectedValue);
  }

  contactTypeSelect.on("change", function () {
    populateMessageTextarea();
  });

  $("#send-interview-sms").on("click", function (e) {
    e.preventDefault();
    const contactId = $(this).data("id");
    const content = $(this).data("content");
    const number = $(this).data("number");
    const templates = $(this).data("templates");
    const options = generateOptions(templates);
    contactTypeSelect.empty().append(options);
    $("#message-content").val(content);
    $("#send-sms-modal-number").html(number);
    $("#send-sms-form input[name=contact_id]").val(contactId);
    populateMessageTextarea();
  });

  $("#filter-btn").on("click", function () {
    $(".d-md-flex").toggleClass("d-none");
  });

  $(document).on("click", "#contact-address", function (e) {
    $("#direction-modal").modal("show");
    $(".loading").show();
    $(".direction-details").hide();

    const address = $(this).data("address");

    $.ajax({
      url: "/contacts/directions",
      type: "GET",
      data: { address: address },
      success: function (response) {
        // Hide the loading state and show the loaded content
        $(".loading").hide();
        $(".direction-details").html(response).show();
      },
    });
  });

  $(document).on("click", "#direction-modal-close", function (e) {
    $("#direction-modal").modal("hide");
  });
});
