$('#service-history-load-more-button').on('click', function () {
  loadMoreDrivers($(this).data('page'));
});

function loadMoreDrivers(page) {
  let currentURL = window.location.href;
  let parts = currentURL.split('/');
  let lineId = parts[3];

  $('#service-history-load-more-button .button-text').text('読み込み中');
  $('#service-history-load-more-button .ic').css('display', 'none');

  let parameters = {
    page: page,
    line_id: lineId,
    vehicle_id: getStatusParam(currentURL)
  };

  $.ajax({
    type: 'GET',
    url: '/service_histories',
    data: parameters,
    dataType: 'script',
    success: function (data) {
      $('#service-history-load-more-button .button-text').text('もっと見る');
      $('#service-history-load-more-button .ic').css('display', 'inline');

      if (!data || data.trim() === "") {
        $('#service-history-load-more-button').hide();
      } else {
        $('.service-history-list').append(data);
        $('#service-history-load-more-button').data('page', page + 1);
      }
    },
    error: function (xhr, status, error) {
      console.error('Error loading more drivers:', error);
      $('#service-history-load-more-button .button-text').text('もっと見る');
      $('#service-history-load-more-button .ic').css('display', 'inline');
    },
  });
}

function getStatusParam(currentURL) {
  let urlParts = currentURL.split('?');
  let queryString = urlParts[1];
  let urlParams = new URLSearchParams(queryString);
  let statusValues = null;
  if (urlParams.has('vehicle_id')) {
    statusValues = urlParams.getAll('vehicle_id');
  }
  return statusValues;
}
