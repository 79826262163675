$(function () {
  $(document).on('click', '.btn-delete', function () {
    const id = this.dataset.id;
    const idPhoto = this.dataset.imgid;
    const element = document.getElementById('img-'+id+'');
    element.remove();
    this.remove();
    const files = document.getElementById('image-upload').files;
    if (files.length > 1) {
      const newFiles = Array.from(files);
      newFiles.splice(id, 1);
      const fileInput = document.getElementById('image-upload');
      const dataTransfer = new DataTransfer();
      newFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });
      fileInput.files = dataTransfer.files;
    } else {
      // If there's only one file left, reset the file input element to clear its value
      const fileInput = document.getElementById('image-upload');
      fileInput.value = '';
    }
    const imageRemoveID = $('#image-remove');
    if (imageRemoveID.length > 0) {
      imageRemoveID.val(imageRemoveID.val() + "," + idPhoto);
    }
  });
});

$(document).on('change', '#image-upload', function () {
  $('#driver_insurance_image_preview').html('');

  for (var i = 0; i < $(this)[0].files.length; i++) {
    if (this.files[i].type == 'application/pdf') {
      $('#driver_insurance_image_preview').append('<embed src="' + window.URL.createObjectURL(this.files[i]) + '#toolbar=0&navpanes=0&scrollbar=0&rs:embed=true" width="100%" height="600px" type="application/pdf" />');
    } else {
      $('#driver_insurance_image_preview').append('<span class="btn-delete" data-id="'+i+'">削除</span>');
      $('#driver_insurance_image_preview').append('<img src="' + window.URL.createObjectURL(this.files[i]) + '" width="100px" height="100px" id="img-'+i+'"/>');
    }
  }
});
