$(document).ready(function () {
  if ($('.driver-page').hasClass('otp-page')) {
    var resendOTPCounter = 60;

    setInterval(function() {
      resendOTPCounter--;

      if (resendOTPCounter >= 0) {
        $('#CountTime').text(resendOTPCounter);
      }

      if (resendOTPCounter === 0) {
        $('.otp-time').addClass('active');
        $('.countdown-wrapper').css('display', 'none');
        clearInterval(resendOTPCounter);
      }
    }, 1000);
  }

  $('.otp-time').click(function(e) {
    e.preventDefault();

    $('#LinePopup').css('display', 'none');
    $('#LoadingWrapper').css('display', 'block');

    var _this = $(this);

    $.ajax({
      url: $(this).attr('href'),
      dataType: 'json',
      type: 'post',
      data: {
        line_context: $(this).data('line-context'),
        sid: $(this).data('sid')
      },
      success: function(data) {
        if (data.status == 'success') {
          var baseURL = _this.data('base-url');
          var lineID = _this.data('line-id');
          var returnURL = _this.data('return-url');
          var sid = data.sid;

          var reloadURL = baseURL + '?line_id=' + lineID + '&return_url=' + returnURL + '&sid=' + sid;
          window.location.href = reloadURL;
        } else if (data.status == 'error') {
          $('#LoadingWrapper').css('display', 'none');
          $('#LinePopup .popup-msg').text(data.msg);
          $('#LinePopup').css('display', 'block');
        }
      },
      error: function(jqXhr, textStatus, error) {
        console.log( error );
      }
    });
  });

  $('#LinePopup .btn-action').click(function(e) {
    e.preventDefault();

    $('#LinePopup').css('display', 'none');
    $('#LinePopup .popup-msg').text('');

    if ($(this).data('next-action') == 'redirect') {
      var url = $('#LineReturnURL').val();

      if (url) {
        window.location.href = url;
      }
    }
  });

  $('#LineLoginForm').submit(function(e) {
    e.preventDefault();

    $('#LinePopup').css('display', 'none');
    $('#LoadingWrapper').css('display', 'block');

    $.ajax({
      url: $(this).attr('action'),
      dataType: 'json',
      type: 'post',
      data: {
        line_context: $('#LineContext').val(),
        mobile_phone: $('#LineMobilePhone').val(),
        group: $('#LineMobileGroup').val()
      },
      success: function(data) {
        if (data.status == 'success') {
          window.location.href = $('#LineOTPURL').val() + '&sid=' + data.sid + '&phone_number=' + $('#LineMobilePhone').val();
        } else if (data.status == 'error') {
          $('#LoadingWrapper').css('display', 'none');
          $('#LinePopup .popup-msg').text(data.msg);
          $('#LinePopup').css('display', 'block');
        }
      },
      error: function(jqXhr, textStatus, error) {
        console.log( error );
      }
    });
  });

  $('#LineOTPForm').submit(function(e) {
    e.preventDefault();

    $('#LinePopup').css('display', 'none');
    $('#LinePopup .popup-title').css('display', 'block');
    $('#LinePopup .popup-icon').css('display', 'none');
    $('#LoadingWrapper').css('display', 'block');

    $.ajax({
      url: $(this).attr('action'),
      dataType: 'json',
      type: 'post',
      data: {
        sid: $('#LineOTPSid').val(),
        line_id: $('#LineId').val(),
        line_context: $('#LineContext').val(),
        otp: $('#LineOTP').val()
      },
      success: function(data) {
        if (data.status == 'success') {
          $('#LoadingWrapper').css('display', 'none');
          $('#LinePopup .popup-title').css('display', 'none');
          $('#LinePopup .popup-icon').css('display', 'block');
          $('#LinePopup .popup-msg').text(data.msg);
          $('#LinePopup .btn-action').data('next-action', 'redirect');
          $('#LinePopup').css('display', 'block');
        } else if (data.status == 'error') {
          $('#LoadingWrapper').css('display', 'none');
          $('#LinePopup .popup-msg').text(data.msg);
          $('#LinePopup').css('display', 'block');
        }
      },
      error: function(jqXhr, textStatus, error) {
        console.log( error );
      }
    });
  });
});