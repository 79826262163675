function handleFileInput(fileInputId, previewContainerId) {
  const fileInput = document.getElementById(fileInputId);
  const previewContainer = document.getElementById(previewContainerId);

  previewContainer.innerHTML = "";

  for (let i = 0; i < fileInput.files.length; i++) {
    const file = fileInput.files[i];

    const fileElement = file.type === "application/pdf"
      ? `<embed src="${window.URL.createObjectURL(file)}#toolbar=0&navpanes=0&scrollbar=0&rs:embed=true" width="100%" height="600px" type="application/pdf" />`
      : `<img src="${window.URL.createObjectURL(file)}" width="100px" height="100px"/>`;

    previewContainer.insertAdjacentHTML("beforeend", fileElement);
  }
}

$(document).on("change", "#vehicle_inspection_certificate", function () {
  handleFileInput("vehicle_inspection_certificate", "vehicle_inspection_certificate_preview");
});

$(document).on("change", "#vehicle_insurance_certificate", function () {
  handleFileInput("vehicle_insurance_certificate", "vehicle_insurance_certificate_preview");
});

$(document).on("change", "#vehicle_liability_insurance", function () {
  handleFileInput("vehicle_liability_insurance", "vehicle_liability_insurance_preview");
});

$(document).on("change", "#vehicle_vehicle_delivery_images", function () {
  handleFileInput("vehicle_vehicle_delivery_images", "vehicle_delivery_images_preview");
});

$(document).on("change", "#vehicle_last_oil_change_receipt", function () {
  handleFileInput("vehicle_last_oil_change_receipt", "vehicle_last_oil_change_receipt_preview");
});

$(document).on("change", "#service_history_receipt", function () {
  handleFileInput("service_history_receipt", "service_history_receipt_preview");
});

function setupImageModal(classSelector) {
  $(classSelector).click(function(e) {
    e.preventDefault();
    var imageUrl = $(this).find('img').attr('src');
    $('#imageModal .modal-body').html('<img src="' + imageUrl + '" class="img-fluid">');
    $('#imageModal').modal('show');
  });
}

$(document).ready(function() {
  setupImageModal('.info-vehicle-img a');
  setupImageModal('.inspection-certificate a');
  setupImageModal('.insurance-certificate a');
  setupImageModal('.liability-insurance a');
});
