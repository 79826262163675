$('input.datetimepicker').daterangepicker({
  singleDatePicker: true,
  autoUpdateInput: false,
  timePicker: true,
  timePicker24Hour: true,
  drops: 'auto',
  locale: {
    "format": "YYYY/MM/DD HH:mm",
    "separator": " ~ ",
    "applyLabel": "応用",
    "cancelLabel": "キャンセル",
    "fromLabel": "から",
    "toLabel": "に",
    "customRangeLabel": "カスタム",
    "daysOfWeek": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "monthNames": [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "firstDay": 1
  }
}).on('show.daterangepicker', function (ev, picker) {
}).on('apply.daterangepicker', function(ev, picker) {
  $(this).val(picker.startDate.format("YYYY/MM/DD HH:mm"));
  if ($(".js-booking-date-span").length > 0) {
    $(".js-booking-date-span").text(picker.startDate.format("YYYY/MM/DD HH:mm"));
  }
  if ($('.js-update-booking-date-params').length > 0) {
    $('.js-update-booking-date-params').val(picker.startDate.format("YYYY/MM/DD HH:mm"));
  }
  if ($(".js-create-booking-date-span").length > 0) {
    $(".js-create-booking-date-span").text(picker.startDate.format("YYYY/MM/DD HH:mm"));
  }
  $('.job-action .btn').removeAttr('disabled');
});

$('input.jsdatepicker').daterangepicker({
  singleDatePicker: true,
  autoUpdateInput: false,
  timePicker: false,
  timePicker24Hour: false,
  drops: 'auto',
  locale: {
    "format": "YYYY/MM/DD",
    "separator": " ~ ",
    "applyLabel": "応用",
    "cancelLabel": "キャンセル",
    "fromLabel": "から",
    "toLabel": "に",
    "customRangeLabel": "カスタム",
    "daysOfWeek": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "monthNames": [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "firstDay": 1
  }
}).on('show.daterangepicker', function (ev, picker) {
}).on('apply.daterangepicker', function(ev, picker) {
  $(this).val(picker.startDate.format("YYYY/MM/DD"));
  if ($(".js-booking-date-span").length > 0) {
    $(".js-booking-date-span").text(picker.startDate.format("YYYY/MM/DD"));
  }
  if ($('.js-update-booking-date-params').length > 0) {
    $('.js-update-booking-date-params').val(picker.startDate.format("YYYY/MM/DD"));
  }
  if ($(".js-create-booking-date-span").length > 0) {
    $(".js-create-booking-date-span").text(picker.startDate.format("YYYY/MM/DD"));
  }
  $('.job-action .btn').removeAttr('disabled');
});