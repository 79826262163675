$(document).on("cocoon:after-insert", '#extra-fees-block', function() {
  $('input.jsdatepicker').daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    timePicker: false,
    timePicker24Hour: false,
    drops: 'auto',
    locale: {
      "format": "YYYY/MM/DD",
      "separator": " ~ ",
      "applyLabel": "応用",
      "cancelLabel": "キャンセル",
      "fromLabel": "から",
      "toLabel": "に",
      "customRangeLabel": "カスタム",
      "daysOfWeek": [
        "日",
        "月",
        "火",
        "水",
        "木",
        "金",
        "土"
      ],
      "monthNames": [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月"
      ],
      "firstDay": 1
    }
  }).on('show.daterangepicker', function (ev, picker) {
  }).on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format("YYYY/MM/DD"));
  });
});

$(document).on("ready", function() {
  $('.js-timepicker').timepicker({
    timeFormat: 'HH:mm',
    interval: 60,
    minTime: '0',
    maxTime: '23:00',
    defaultTime: '8',
    startTime: '00:00',
    dynamic: false,
    dropdown: true,
    scrollbar: true
  })
});
