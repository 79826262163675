$(document).ready(function (e) {
  // initPrjTypeCb();
  hideRemoveElement("remove-extra-fee-item", 0);

  $(".toggle-project-show").click(function (e) {
    e.preventDefault();

    if ($(".project-confirm-item").hasClass("show-full")) {
      $(".project-confirm-item").removeClass("show-full");
    } else {
      $(".project-confirm-item").addClass("show-full");
    }
  });

  $(".project-form.project-form-js .switch-prj-mode .btn-switch").click(function (e) {
    e.preventDefault();
    if ($(this).hasClass("disabled")) {
      return false;
    }
    let index = $(this).data("prj-mode") === "term" ? 0 : 1;
    let startDate = $("#project_start_date")
    let termTab = $(".project-form.project-form-js .switch-prj-mode .btn-switch[data-prj-mode='term']");
    let spotTab = $(".project-form.project-form-js .switch-prj-mode .btn-switch[data-prj-mode='spot']");
    if (index === 0) {
      $(".js-project-date-block").find("input").prop("disabled", false).prop("readonly", false);
      $("#project_project_type").val("term");
      startDate.prop("required", false);
      // get sibling label of project_start_date and remove span required inside label
      startDate.siblings("label").find("span").remove();
        if (!termTab.hasClass("active")) {
            termTab.addClass("active");
        }
        if (spotTab.hasClass("active")) {
            spotTab.removeClass("active");
        }
    } else {
      $(".js-project-date-block").find("input").prop("disabled", true).prop("readonly", true);
      $("#project_project_type").val("spot");
      startDate.prop("required", true);
      // get sibling label of project_start_date and add span required inside label if not exist
      if (startDate.siblings("label").find("span").length === 0) {
          startDate.siblings("label").append("<span class=\"text-danger\">（必須）</span>");
      }
      if (!spotTab.hasClass("active")) {
        spotTab.addClass("active");
      }
      if (termTab.hasClass("active")) {
        termTab.removeClass("active");
      }
    }
  });

  // hide elements has project-external-fields class when prj-type-cb checkbox is checked
  $("#prj-type-cb").change(function () {
    $(".project-external-fields").toggleClass("d-none");
  });

  // Suggest drivers
  $(".driver-info-page .btn-suggest-driver").click(function (e) {
    e.preventDefault();

    $("#project_project_size").parent().removeClass("has-error");

    let maxPick = parseInt($("#project_project_size").data("project-size"));

    if (!isNaN(maxPick)) {
      $("#MaxPick").text(maxPick);

      window.scrollTo({ top: 0, behavior: "smooth" });

      $(".project-form").css("display", "none");
      $("#ProjectDriversPicker #BtnPickedDrivers").attr("data-is-suggest", 1);
      $("#ProjectDriversPicker").css("display", "block");
    } else {
      $("#project_project_size").parent().addClass("has-error");
    }
  });

  $("#clearProjectDriverFilterForm").click(function (e) {
    e.preventDefault();

    $("#ProjectDriversPicker").css("display", "none");
    $("#ProjectForm").css("display", "block");
    $("#ProjectShowForm").css("display", "block");
  });

  $('.list-drivers .driver-item input[type="checkbox"]').change(function (e) {
    e.preventDefault();

    let maxPick = parseInt($("#MaxPick").text());
    let totalPicked = $(
      '.list-drivers .driver-item input[type="checkbox"]:checked'
    ).length;

    if (totalPicked <= maxPick) {
      $("#TotalDrivers").text(totalPicked);
    } else {
      $(this).prop("checked", false);
    }

    if ($(this).is(":checked")) {
      $(this).closest(".driver-item").addClass("checkbox-checked");
    } else {
      $(this).closest(".driver-item").removeClass("checkbox-checked");
    }

    let checkedItems = $(".driver-item.checkbox-checked")
      .map(function () {
        return $(this).attr("data-driver-id");
      })
      .get()
      .join(",");

    $(".js-drivers-selected").val(checkedItems);
  });

  $("#BtnPickedDrivers").click(function (e) {
    e.preventDefault();

    let drivers = "";
    let drivers_arr = [];
    let drivers_list = "";

    let total = $(
      '.list-drivers .driver-item input[type="checkbox"]:checked'
    ).length;

    if (total == 0) {
      drivers = '<span class="plc">担当者</span>';
    } else {
      $('.list-drivers .driver-item input[type="checkbox"]:checked').each(
        function (i, el) {
          var driver = $(el).parent();
          drivers_arr.push(driver.data("driver-id"));

          drivers +=
            '<div class="picked-item" data-driver-id="' +
            driver.data("driver-id") +
            '">' +
            driver.find(".driver-name").text() +
            '<span class="remove-picked-item"></span></div>';
        }
      );
      drivers_list = drivers_arr.join();
    }

    $(".js-create-drivers-list").val(drivers_list);

    if ($("#BtnPickedDrivers").data("is-suggest") == 1) {
      let agencyId = $(".js-agency-id").val();
      let projectId = $(".js-project-id").val();

      $.ajax({
        url: `/agencies/${agencyId}/projects/suggest_drivers`,
        method: "POST",
        data: {
          agency_id: agencyId,
          project_id: projectId,
          drivers_list: drivers_list,
          authenticity_token: $('meta[name="csrf-token"]').attr("content"),
        },
      }).done(function () {
        location.href = `/agencies/${agencyId}/projects`;
      });
    } else {
      $("#PickedDriversResult").html(drivers);

      $("#ProjectDriversPicker").css("display", "none");
      $("#ProjectForm").css("display", "block");
    }
  });

  $(
    ".bottom-menu-wrapper .menu-header-close, .bottom-menu-form .btn-cancel"
  ).click(function (e) {
    e.preventDefault();

    $(".bottom-menu-wrapper").addClass("not-show");
    $(".bottom-menu-inner").addClass("not-show");

    $(".bottom-menu-wrapper .bottom-menu-content").css({ bottom: "-500px" });
  });

  $("#ProjectSortBtn").click(function (e) {
    e.preventDefault();

    $(".bottom-menu-wrapper").removeClass("not-show");
    $(".bottom-menu-inner").addClass("not-show");
    $("#ProjectSortArea").removeClass("not-show");

    $(".bottom-menu-wrapper .bottom-menu-content").animate({ bottom: 0 }, 300);
  });

  $("#ProjectFilterBtn").click(function (e) {
    e.preventDefault();

    $(".bottom-menu-wrapper").removeClass("not-show");
    $(".bottom-menu-inner").addClass("not-show");
    $("#ProjectFilterArea").removeClass("not-show");

    $(".bottom-menu-wrapper .bottom-menu-content").animate({ bottom: 0 }, 300);
  });

  $(".show-bottom-modal-btn-actions").click(function (e) {
    e.preventDefault();

    $(".bottom-menu-wrapper").removeClass("not-show");
    $(".bottom-menu-inner").addClass("not-show");
    $("#CalendarActions").removeClass("not-show");

    $(".bottom-menu-wrapper .bottom-menu-content").animate({ bottom: 0 }, 200);
  });

  $('.close-btn-add-actions').click(function (e) {
    e.preventDefault();

    $(".bottom-menu-wrapper").addClass("not-show");
    $(".bottom-menu-inner").addClass("not-show");

    $(".bottom-menu-wrapper .bottom-menu-content").css({ bottom: "-500px" });
  });

  $(".prj-menu-short .rdbox").click(function (e) {
    e.preventDefault();

    $(".prj-menu-short .rdbox").removeClass("selected");
    $('.prj-menu-short .rdbox input[name="prj_sort_type"]').removeAttr(
      "checked"
    );

    $(this).addClass("selected");
    $(this).find('input[name="prj_sort_type"]').attr("checked", "checked");
  });

  $(".btn-form.btn-apply").click(function (e) {
    e.preventDefault();
    window.location.href = buildProjectURL();
  });

  $('.projects-quick-filter .filter-item').click(function(e) {
    e.preventDefault();

    let activeCount = $('.projects-quick-filter .filter-item.active').length;

    if (activeCount == 0) {
      window.location.href = $(this).attr('href');
    } else if (activeCount == 2) {
      window.location.href = $(this).data('revert-url');
    } else if (!$(this).hasClass('active')) {
      window.location.href = `${$(this).data('base-url')}`;
    } else {
      window.location.href = `${$(this).data('base-url')}?project_type=3`;
    }
  });

  $('#agency_select').change(function() {
    let agencyId = $(this).val();
    $('#driver_select').html([]);
    updateDriverDropdown(agencyId);
  });

  function updateDriverDropdown(agencyId) {
    $.ajax({
      url: `/agencies/${agencyId}/agent-drivers`,
      type: 'GET',
      dataType: 'json',
      success: function(data) {
        let options = '';
        $.each(data, function(index, driver) {
          let selected = index === 0 ? 'selected="selected"' : '';
          options += '<option ' + selected +' value="' + driver.id + '">' + driver.name + '</option>';
        });
        $('#driver_select').html(options);
      }
    });
  }  
});

$(document).on(
  "click",
  ".list-picked-drivers .remove-picked-item",
  function (e) {
    e.preventDefault();

    $(
      '[data-driver-id="' +
        $(this).parent().data("driver-id") +
        '"] input[type="checkbox"]'
    ).prop("checked", false);

    let drivers = "";
    let drivers_arr = [];
    let drivers_list = "";

    let total = $('.list-drivers .driver-item input[type="checkbox"]:checked').length;

    if (total == 0) {
      drivers = '<span class="plc">担当者</span>';
    } else {
      $('.list-drivers .driver-item input[type="checkbox"]:checked').each(
        function (i, el) {
          var driver = $(el).parent();
          drivers_arr.push(driver.data("driver-id"));

          drivers +=
            '<div class="picked-item" data-driver-id="' +
            driver.data("driver-id") +
            '">' +
            driver.find(".driver-name").text() +
            '<span class="remove-picked-item"></span></div>';
        }
      );
      drivers_list = drivers_arr.join();
    }

    $("#PickedDriversResult").html(drivers);

    // TODO: Update input values...
    $(".js-create-drivers-list").val(drivers_list);
  }
);

function buildProjectURL() {
  let baseUrl = window.location.pathname;
  let params = new URLSearchParams(window.location.search);
  let projectType = params.get('project_type');
  let projectTypeParam = projectType ? `project_type=${projectType}` : '';
  let sort = $(
    '.prj-menu-short .rdbox.selected input[name="prj_sort_type"]'
  ).val();
  let limit = $("#prj-status-cb").is(":checked") ? "true" : "false";
  let startDate = $("#PrjStartDate").val();
  let endDate = $("#PrjEndDate").val();
  let price = $("#PrjPrice").val();

  return `${baseUrl}?${projectTypeParam}&sort=${sort}&driver_limit=${limit}&price=${price}&start_date=${startDate}&end_date=${endDate}`;
}

$(document).on("click", ".js-confirm-drivers", function (e) {
  e.preventDefault();

  if (parseInt($("#TotalDrivers").text()) <= 0) {
    return false;
  }

  let driversSelected = $(".js-drivers-selected").val();
  let projectId = $(".js-project-id").val();
  let agencyId = $(".js-agency-id").val();

  $.ajax({
    url: `/agencies/${agencyId}/projects/drivers_confirm`,
    method: "POST",
    data: {
      project_id: projectId,
      drivers_selected: driversSelected,
      authenticity_token: $('meta[name="csrf-token"]').attr("content"),
    },
  }).done(function (rsp) {
    location.href = `/agencies/${agencyId}/projects`;
  });
});

function hideRemoveElement (className, number) {
  let elements = document.getElementsByClassName(className);
  if (elements.length > 0) {
    elements[number].classList.add("inactive");
  }
}

$(document).on("change", "#price-type-cb", function () {
  $("#project-day-salary").toggleClass("d-none");
  $("#project-parcels-salary").toggleClass("d-none");
});

$(document).on('change', '#project_supplier_id', function() {
  getDataSupplier(this);
});

function getDataSupplier(element) {
  let selectedValue = $(element).val();
  let host = window.location.protocol + "//" + window.location.host;
  $.ajax({
    url: host + '/admin/get_person_charge_options',
    type: 'GET',
    dataType: 'json',
    data: { supplier_id: selectedValue },
    success: function(data) {
      let options = '';
      $.each(data, function(index, value) {
        options += '<option value="' + value.id + '">' + value.name + '</option>';
      });
      $('#child_select_container').html('<select id="child_select" class="form-control form-custom">' + options + '</select>');
    }
  });
}
