$(document).ready(function () {
    $('input[name="q[date_range]"]').change(function () {
        const selectedValue = $('input[name="q[date_range]"]:checked').val();
        let startDate;
        let endDate;
        let lock = true;
        switch (selectedValue) {
            case "current_month":
                startDate = moment().startOf('month').format('YYYY-MM-DD');
                endDate = moment().endOf('month').format('YYYY-MM-DD');
                break;
            case "last_month":
                startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                break;
            case "custom":
                lock = false;
                break;
            case "last_7_days":
                startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case "last_30_days":
                startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case "last_60_days":
                startDate = moment().subtract(60, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case "last_90_days":
                startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
        }
        const startDateInput = $('[name="start_date"]');
        const endDateInput = $('[name="end_date"]');
        startDateInput.val(startDate);
        endDateInput.val(endDate);
        startDateInput.prop('readonly', lock);
        endDateInput.prop('readonly', lock);
    });
});
