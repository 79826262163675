$(document).ready(function () {
  $(".driver-form input").keypress(function (e) {
    if (e.which == 13) {
      e.preventDefault();
      return false;
    }
  });

  $(".drivers-calendar .list-drivers .driver-item").click(function (e) {
    e.preventDefault();

    location.href = $(this).data("driver-url");
  });

  $(document).on("change", ".js-auto-submit", function () {
    $(".btn-submit").trigger("click");
  });

  $(document).on("click", ".ic-btn-upload", function (e) {
    e.preventDefault();

    $(this).parent().find('input[type="file"]').trigger("click");
  });

  $(document).on("change", "#driver_insurance_image", function () {
    $("#driver_insurance_image_preview").html("");

    for (let i = 0; i < $(this)[0].files.length; i++) {
      if (this.files[i].type == "application/pdf") {
        $("#driver_insurance_image_preview").append(
          '<embed src="' +
            window.URL.createObjectURL(this.files[i]) +
            '#toolbar=0&navpanes=0&scrollbar=0&rs:embed=true" width="100%" height="600px" type="application/pdf" />'
        );
      } else {
        $("#driver_insurance_image_preview").append(
          '<img src="' +
            window.URL.createObjectURL(this.files[i]) +
            '" width="100px" height="100px"/>'
        );
      }
    }
  });

  $(document).on("change", "#driver_license_image", function () {
    $("#driver_license_image_preview").html("");

    for (let i = 0; i < $(this)[0].files.length; i++) {
      if (this.files[i].type == "application/pdf") {
        $("#driver_license_image_preview").append(
          '<embed src="' +
            window.URL.createObjectURL(this.files[i]) +
            '#toolbar=0&navpanes=0&scrollbar=0&rs:embed=true" width="100%" height="600px" type="application/pdf" />'
        );
      } else {
        $("#driver_license_image_preview").append(
          '<img src="' +
            window.URL.createObjectURL(this.files[i]) +
            '" width="100px" height="100px"/>'
        );
      }
    }
  });

  $(".img-preview-wrapper .img-preview-item").click(function (e) {
    e.preventDefault();

    $("#ImgLightbox").html(
      '<img src="' + $(this).find("img").attr("src") + '" />'
    );

    $(".img-lightbox-wrapper").addClass("show-box");
  });

  $(".img-preview-wrapper .pdf-preview-item a").click(function (e) {
    e.preventDefault();

    $("#ImgLightbox").html(
      '<embed class="pdf-frame" src="' +
        $(this).data("pdf-url") +
        '" width="100%" height="100%" type="application/pdf" />'
    );

    $(".img-lightbox-wrapper").addClass("pdf-wrapper");
    $(".img-lightbox-wrapper").addClass("show-box");
  });

  $(".btn-close-lightbox").click(function (e) {
    e.preventDefault();

    $(".img-lightbox-wrapper").removeClass("show-box");

    $("#ImgLightbox").html("");
  });

  $(document).on("change", "#driver_resume", function () {
    $("#driver_resume_preview").html("");

    for (let i = 0; i < $(this)[0].files.length; i++) {
      if (this.files[i].type == "application/pdf") {
        $("#driver_resume_preview").append(
          '<embed src="' +
            window.URL.createObjectURL(this.files[i]) +
            '#toolbar=0&navpanes=0&scrollbar=0&rs:embed=true" width="100%" height="600px" type="application/pdf" />'
        );
      } else {
        $("#driver_resume_preview").append(
          '<img src="' +
            window.URL.createObjectURL(this.files[i]) +
            '" width="100px" height="100px"/>'
        );
      }
    }
  });

  $(document).on(
    "change",
    "#driver_vehicle_inspection_certificate",
    function () {
      $("#vehicle_inspection_certificate_preview").html("");

      for (let i = 0; i < $(this)[0].files.length; i++) {
        if (this.files[i].type == "application/pdf") {
          $("#vehicle_inspection_certificate_preview").append(
            '<embed src="' +
              window.URL.createObjectURL(this.files[i]) +
              '#toolbar=0&navpanes=0&scrollbar=0&rs:embed=true" width="100%" height="600px" type="application/pdf" />'
          );
        } else {
          $("#vehicle_inspection_certificate_preview").append(
            '<img src="' +
              window.URL.createObjectURL(this.files[i]) +
              '" width="100px" height="100px"/>'
          );
        }
      }
    }
  );

  $(document).on(
    "change",
    "#driver_automobile_liability_insurance",
    function () {
      $("#automobile_liability_insurance_preview").html("");

      for (let i = 0; i < $(this)[0].files.length; i++) {
        if (this.files[i].type == "application/pdf") {
          $("#automobile_liability_insurance_preview").append(
            '<embed src="' +
              window.URL.createObjectURL(this.files[i]) +
              '#toolbar=0&navpanes=0&scrollbar=0&rs:embed=true" width="100%" height="600px" type="application/pdf" />'
          );
        } else {
          $("#automobile_liability_insurance_preview").append(
            '<img src="' +
              window.URL.createObjectURL(this.files[i]) +
              '" width="100px" height="100px"/>'
          );
        }
      }
    }
  );

  $(document).on("change", "#driver_driving_record_certificate", function () {
    $("#driving_record_certificate_preview").html("");

    for (let i = 0; i < $(this)[0].files.length; i++) {
      if (this.files[i].type == "application/pdf") {
        $("#driving_record_certificate_preview").append(
          '<embed src="' +
            window.URL.createObjectURL(this.files[i]) +
            '#toolbar=0&navpanes=0&scrollbar=0&rs:embed=true" width="100%" height="600px" type="application/pdf" />'
        );
      } else {
        $("#driving_record_certificate_preview").append(
          '<img src="' +
            window.URL.createObjectURL(this.files[i]) +
            '" width="100px" height="100px"/>'
        );
      }
    }
  });

  $(".by-week").click(function (e) {
    const driver_id = $(".switch-driver").find(":selected").val();
    const date = $(this).attr("id");
    const lineId = $(".js-line-id").val();

    $.ajax({
      url: `/${lineId}/agency/drivers/calendar/view_by_month`,
      type: "GET",
      data: {
        date: date,
        driver_id: driver_id,
        line_id: lineId,
        reopen_partial: true,
        authenticity_token: $('meta[name="csrf-token"]').attr("content"),
      },
    }).done(function (res) {
      $(".view-by-week").html("");
      $(".view-by-week").html(res);
    });
  });

  $(".driver-by-week").click(function (e) {
    const date = $(this).attr("id");
    let lineId = $(".js-line-id").val();

    $.ajax({
      url: `/drivers/${lineId}/calendar`,
      type: "GET",
      data: {
        date: date,
        line_id: lineId,
        flag: true,
        authenticity_token: $('meta[name="csrf-token"]').attr("content"),
      },
    }).done(function (res) {
      $(".view-driver-by-week").html("");
      $(".view-driver-by-week").html(res);
    });
  });

  $(".by-rest").click(function (e) {
    const date = $(this).attr("id");
    let list_date = $("#date")
      .val()
      .split(",")
      .filter((item) => item);

    if ($(this).parent().hasClass("rest-item")) {
      list_date = $.grep(list_date, function (n) {
        return n != date;
      });
    } else {
      list_date.push(date);
    }

    $(this).parent().toggleClass("rest-item");
    $("#date").val(list_date.join(","));
  });

  $(".driver-by-rest").click(function (e) {
    const date = $(this).attr("id");
    let list_date = $("#date")
      .val()
      .split(",")
      .filter((item) => item);

    if ($(this).parent().hasClass("rest-item")) {
      list_date = $.grep(list_date, function (n) {
        return n != date;
      });
    } else {
      list_date.push(date);
    }
    $(this).parent().toggleClass("rest-item");

    $("#date").val(list_date.join(","));
  });

  $(".btn-calendar-rest-register").click(function () {
    const driver_id = $(".switch-driver-rest").find(":selected").val();
    const project_id = "";
    $("#driver_id").val(driver_id);
    $("#project_id").val(project_id);
    $(".form_rest").submit();
  });

  $(".btn-driver-rest-register").click(function () {
    const project_id = "";
    $("#project_id").val(project_id);
    $(".form_rest").submit();
  });

  $(".by-work").click(function (e) {
    e.preventDefault();
    const date = $(this).attr("id");
    let list_date = $("#date")
      .val()
      .split(",")
      .filter((item) => item);

    if ($(this).parent().hasClass("work-item")) {
      list_date = $.grep(list_date, function (n) {
        return n !== date;
      });
    } else {
      list_date.push(date);
    }

    $(this).parent().toggleClass("work-item");
    $("#date").val(list_date.join(","));
  });

  $(".driver-by-work").click(function (e) {
    const date = $(this).attr("id");
    let list_date = $("#date")
      .val()
      .split(",")
      .filter((item) => item);

    if ($(this).parent().hasClass("work-item")) {
      list_date = $.grep(list_date, function (n) {
        return n != date;
      });
    } else {
      list_date.push(date);
    }

    $(this).parent().toggleClass("work-item");
    $("#date").val(list_date.join(","));
  });

  $(".btn-calendar-work-register").click(function () {
    const driver_id = $("#agency-work-register-driver-selection").val();
    const project_id = $("#agency-work-register-project-selection").val();
    $("#driver_id").val(driver_id);
    $("#project_id").val(project_id);
    $(".form_work").submit();
  });

  $(".btn-driver-work-register").click(function () {
    const project_id = $("#driver-work-register-project-selection").val();
    $("#project_id").val(project_id);
    $(".form_work").submit();
  });

  $("#agency-work-register-driver-selection").change(function (e) {
    e.preventDefault();
    const driver_id = $(this).val();
    const lineId = $(this).data("line-id");
    const startDate = $(this).data("current-date");
    location.href = `/${lineId}/agency/drivers/calendar/work_register?date=${startDate}&driver_id=${driver_id}`;
  });

  $("#agency-rest-register-driver-selection").change(function (e) {
    e.preventDefault();
    const driver_id = $(this).val();
    const lineId = $(this).data("line-id");
    const startDate = $(this).data("current-date");
    location.href = `/${lineId}/agency/drivers/calendar/rest_register?date=${startDate}&driver_id=${driver_id}`;
  });

  $("#agency-month-calendar-driver-selection").change(function (e) {
    e.preventDefault();
    const driver_id = $(this).val();
    const lineId = $(this).data("line-id");
    const startDate = $(this).data("current-date");
    location.href = `/${lineId}/agency/drivers/calendar/view_by_month?date=${startDate}&driver_id=${driver_id}`;
  });

  $(document).on("click", "#driver-address", function (e) {
    $("#direction-modal").modal("show");
    $(".loading").show();
    $(".direction-details").hide();

    const address = $(this).data("address");
    const postcode = $(this).data("postcode");

    $.ajax({
      url: "/driver/directions",
      type: "GET",
      data: { address: address, postcode: postcode },
      success: function (response) {
        // Hide the loading state and show the loaded content
        $(".loading").hide();
        $(".direction-details").html(response).show();
      },
    });
  });

  $(document).on("click", "#direction-modal-close", function (e) {
    $("#direction-modal").modal("hide");
  });
});
