require("bootstrap/dist/css/bootstrap");

var jQuery = require("jquery");

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("./popper.min.js");

require("bootstrap/dist/js/bootstrap");
require("jquery-sparkline/jquery.sparkline");

require("./perfect-scrollbar.jquery.min.js");
require("./waves");
require("./sidebarmenu");
require("./custom");
