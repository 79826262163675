$(document).ready(function () {

  $(document).on('click', '#work-schedule-today', function(e){
    navigate(e, $(this).data('date'));
  });

  $(document).on('click', '#work-schedule-last-month', function(e){
    navigate(e, $(this).data('date'));
  });

  $(document).on('click', '#work-schedule-next-month', function(e){
    navigate(e, $(this).data('date'));
  });

  function navigate(e, date) {
    e.preventDefault();
    $('#offset-date').val(date);
    $('#today-empty').val(false);
    $(".btn-submit").trigger("click");
  };

  $(document).on('click', '#work-schedule-today-empty', function(e){
    e.preventDefault();
    const today_empy = $('#today-empty').val() === 'true';
    $('#today-empty').val(!today_empy);
    $(".btn-submit").trigger("click");
  });
});
