$(document).ready(function () {
  $('#agent_application_status').on('change', function() {
    const selectedValue = $(this).val();
    const statuses =  ["面談予定", "面談済み検討"];
    if (statuses.includes(selectedValue)) {
      $('#interview-date-wraper').removeClass("hidden");
      $('#interview-url-wraper').removeClass("hidden");
    } else {
      $('#interview-date-wraper').addClass("hidden");
      $('#interview-url-wraper').addClass("hidden");
    }
  });

  $('#agent_application_interview_date').on('change', function () {
    const selectedValue = $('#agent_application_status').val();
    const statuses = ["面談予定", "面談済み検討"];
    if (statuses.includes(selectedValue)) {
      $('#agent-info-update').attr('data-target', '#submit-modal');
    } else {
      $('#agent-info-update').attr('data-target', '#confirm-submit-modal');
    }
  })

  $(document).on("click", ".agent-applications-list .send-sms", function(e) {
    e.preventDefault();
    const contactId = $(this).data("id");
    const content = $(this).data("content");
    const number = $(this).data("number");
    $("#message-content").val(content);
    $("#send-sms-modal-number").html(number);
    $("#send-sms-form input[name=agent_application_id]").val(contactId);
  });

  $(document).on("click", "#agent-send-interview-sms", function(e) {
    e.preventDefault();
    const contactId = $(this).data("id");
    const content = $(this).data("content");
    const number = $(this).data("number");
    $("#message-content").val(content);
    $("#send-sms-modal-number").html(number);
    $("#send-sms-form input[name=agent_application_id]").val(contactId);
  });
});
